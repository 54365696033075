export var sectionsProperty = ['sections', 'sec'];
export var fontSubstitutionTableProperty = ['fontSubstitutionTable', 'fst'];
export var characterFormatProperty = ['characterFormat', 'cf'];
export var paragraphFormatProperty = ['paragraphFormat', 'pf'];
export var listsProperty = ['lists', 'li'];
export var abstractListsProperty = ['abstractLists', 'al'];
export var backgroundProperty = ['background', 'bg'];
export var stylesProperty = ['styles', 'sty'];
export var commentsProperty = ['comments', 'cm'];
export var revisionsProperty = ['revisions', 'r'];
export var customXmlProperty = ['customXml', 'cx'];
export var defaultTabWidthProperty = ['defaultTabWidth', 'dtw'];
export var formattingProperty = ['formatting', 'fmt'];
export var trackChangesProperty = ['trackChanges', 'tc'];
export var protectionTypeProperty = ['protectionType', 'pt'];
export var enforcementProperty = ['enforcement', 'enf'];
export var hashValueProperty = ['hashValue', 'hv'];
export var saltValueProperty = ['saltValue', 'sv'];
export var cryptProviderTypeProperty = ['cryptProviderType', 'cpt'];
export var cryptAlgorithmClassProperty = ['cryptAlgorithmClass', 'cac'];
export var cryptAlgorithmTypeProperty = ['cryptAlgorithmType', 'cat'];
export var cryptAlgorithmSidProperty = ['cryptAlgorithmSid', 'cas'];
export var cryptSpinCountProperty = ['cryptSpinCount', 'csc'];
export var doNotUseHTMLParagraphAutoSpacingProperty = ['dontUseHTMLParagraphAutoSpacing', 'dhtml'];
export var alignTablesRowByRowProperty = ['alignTablesRowByRow', 'atrr'];
export var formFieldShadingProperty = ['formFieldShading', 'ffs'];
export var lastParagraphMarkCopiedProperty = ['lastParagraphMarkCopied', 'lpmc'];
export var footnotesProperty = ['footnotes', 'fn'];
export var endnotesProperty = ['endnotes', 'en'];
export var compatibilityModeProperty = ['compatibilityMode', 'comp'];
export var themeFontLanguagesProperty = ['themeFontLanguages', 'tfl'];
export var themesProperty = ['themes', 'thm'];
export var nameProperty = ['name', 'n'];
export var basedOnProperty = ['basedOn', 'b'];
export var nextProperty = ['next', 'nx'];
export var linkProperty = ['link', 'l'];
export var localeIdProperty = ['localeId', 'id'];
export var localeIdFarEastProperty = ['localeIdFarEast', 'idfe'];
export var localeIdBidiProperty = ['localeIdBidi', 'idbi'];
export var boldProperty = ['bold', 'b'];
export var italicProperty = ['italic', 'i'];
export var underlineProperty = ['underline', 'u'];
export var baselineAlignmentProperty = ['baselineAlignment', 'ba'];
export var strikethroughProperty = ['strikethrough', 'st'];
export var highlightColorProperty = ['highlightColor', 'hc'];
export var fontSizeProperty = ['fontSize', 'fsz'];
export var fontColorProperty = ['fontColor', 'fc'];
export var fontFamilyProperty = ['fontFamily', 'ff'];
export var styleNameProperty = ['styleName', 'stn'];
export var bidiProperty = ['bidi', 'bi'];
export var bdoProperty = ['bdo', 'bdo'];
export var breakClearTypeProperty = ['breakClearType', 'bct'];
export var fontSizeBidiProperty = ['fontSizeBidi', 'fszbi'];
export var fontFamilyBidiProperty = ['fontFamilyBidi', 'ffbi'];
export var boldBidiProperty = ['boldBidi', 'bbi'];
export var italicBidiProperty = ['italicBidi', 'ibi'];
export var allCapsProperty = ['allCaps', 'ac'];
export var complexScriptProperty = ['complexScript', 'cs'];
export var fontFamilyAsciiProperty = ['fontFamilyAscii', 'ffa'];
export var fontFamilyFarEastProperty = ['fontFamilyFarEast', 'fffe'];
export var fontFamilyNonFarEastProperty = ['fontFamilyNonFarEast', 'ffnfe'];
export var revisionIdsProperty = ['revisionIds', 'rids'];
export var listIdProperty = ['listId', 'lid'];
export var characterSpacingProperty = ['characterSpacing', 'csp'];
export var scalingProperty = ['scaling', 'sc'];
export var listLevelNumberProperty = ['listLevelNumber', 'lilno'];
export var leftIndentProperty = ['leftIndent', 'lin'];
export var rightIndentProperty = ['rightIndent', 'rin'];
export var firstLineIndentProperty = ['firstLineIndent', 'fin'];
export var textAlignmentProperty = ['textAlignment', 'ta'];
export var afterSpacingProperty = ['afterSpacing', 'as'];
export var beforeSpacingProperty = ['beforeSpacing', 'bs'];
export var spaceAfterAutoProperty = ['spaceAfterAuto', 'saa'];
export var spaceBeforeAutoProperty = ['spaceBeforeAuto', 'sba'];
export var lineSpacingProperty = ['lineSpacing', 'ls'];
export var lineSpacingTypeProperty = ['lineSpacingType', 'lst'];
export var listFormatProperty = ['listFormat', 'lif'];
export var keepWithNextProperty = ['keepWithNext', 'kwn'];
export var widowControlProperty = ['widowControl', 'wc'];
export var keepLinesTogetherProperty = ['keepLinesTogether', 'klt'];
export var outlineLevelProperty = ['outlineLevel', 'ol'];
export var contextualSpacingProperty = ['contextualSpacing', 'cs'];
export var bordersProperty = ['borders', 'bdrs'];
export var tabsProperty = ['tabs', 'tb'];
export var headerDistanceProperty = ['headerDistance', 'hd'];
export var footerDistanceProperty = ['footerDistance', 'fd'];
export var differentFirstPageProperty = ['differentFirstPage', 'dfp'];
export var differentOddAndEvenPagesProperty = ['differentOddAndEvenPages', 'doep'];
export var pageWidthProperty = ['pageWidth', 'pw'];
export var pageHeightProperty = ['pageHeight', 'ph'];
export var leftMarginProperty = ['leftMargin', 'lm'];
export var rightMarginProperty = ['rightMargin', 'rm'];
export var topMarginProperty = ['topMargin', 'tm'];
export var bottomMarginProperty = ['bottomMargin', 'bm'];
export var restartPageNumberingProperty = ['restartPageNumbering', 'rpn'];
export var pageStartingNumberProperty = ['pageStartingNumber', 'psn'];
export var endnoteNumberFormatProperty = ['endnoteNumberFormat', 'enf'];
export var footNoteNumberFormatProperty = ['footNoteNumberFormat', 'fnf'];
export var restartIndexForFootnotesProperty = ['restartIndexForFootnotes', 'rif'];
export var restartIndexForEndnotesProperty = ['restartIndexForEndnotes', 'rie'];
export var initialFootNoteNumberProperty = ['initialFootNoteNumber', 'ifn'];
export var initialEndNoteNumberProperty = ['initialEndNoteNumber', 'ien'];
export var pageNumberStyleProperty = ['pageNumberStyle', 'pgns'];
export var columnsProperty = ['columns', 'cols'];
export var numberOfColumnsProperty = ['numberOfColumns', 'ncols'];
export var equalWidthProperty = ['equalWidth', 'eqw'];
export var lineBetweenColumnsProperty = ['lineBetweenColumns', 'lbtc'];
export var breakCodeProperty = ['breakCode', 'bc'];
export var cellWidthProperty = ['cellWidth', 'cw'];
export var columnSpanProperty = ['columnSpan', 'colsp'];
export var rowSpanProperty = ['rowSpan', 'rwsp'];
export var verticalAlignmentProperty = ['verticalAlignment', 'va'];
export var allowBreakAcrossPagesProperty = ['allowBreakAcrossPages', 'abrk'];
export var isHeaderProperty = ['isHeader', 'hdr'];
export var heightTypeProperty = ['heightType', 'ht'];
export var beforeWidthProperty = ['beforeWidth', 'bw'];
export var afterWidthProperty = ['afterWidth', 'aw'];
export var gridBeforeProperty = ['gridBefore', 'gb'];
export var gridBeforeWidthProperty = ['gridBeforeWidth', 'gbw'];
export var gridBeforeWidthTypeProperty = ['gridBeforeWidthType', 'gbwt'];
export var gridAfterProperty = ['gridAfter', 'ga'];
export var gridAfterWidthProperty = ['gridAfterWidth', 'gaw'];
export var gridAfterWidthTypeProperty = ['gridAfterWidthType', 'gawt'];
export var allowAutoFitProperty = ['allowAutoFit', 'auft'];
export var cellSpacingProperty = ['cellSpacing', 'csp'];
export var shadingProperty = ['shading', 'sd'];
export var tableAlignmentProperty = ['tableAlignment', 'ta'];
export var preferredWidthProperty = ['preferredWidth', 'pw'];
export var preferredWidthTypeProperty = ['preferredWidthType', 'pwt'];
export var horizontalPositionAbsProperty = ['horizontalPositionAbs', 'hpa'];
export var textureProperty = ['texture', 't'];
export var backgroundColorProperty = ['backgroundColor', 'bgc'];
export var foregroundColorProperty = ['foregroundColor', 'fgc'];
export var shadowProperty = ['shadow', 'sh'];
export var hasNoneStyleProperty = ['hasNoneStyle', 'hns'];
export var verticalProperty = ['vertical', 'v'];
export var horizontalProperty = ['horizontal', 'h'];
export var diagonalUpProperty = ['diagonalUp', 'du'];
export var diagonalDownProperty = ['diagonalDown', 'dd'];
export var lineStyleProperty = ['lineStyle', 'ls'];
export var lineWidthProperty = ['lineWidth', 'lw'];
export var layoutProperty = ['layout', 'l'];
export var dataFormatProperty = ['dataFormat', 'df'];
export var yValueProperty = ['yValue', 'y'];
export var chartDataProperty = ['chartData', 'chd'];
export var categoryXNameProperty = ['categoryXName', 'cx'];
export var lineProperty = ['line', 'l'];
export var foreColorProperty = ['foreColor', 'fc'];
export var patternProperty = ['pattern', 'pt'];
export var layoutXProperty = ['layoutX', 'x'];
export var layoutYProperty = ['layoutY', 'y'];
export var directionProperty = ['direction', 'dir'];
export var endStyleProperty = ['endStyle', 'est'];
export var numberValueProperty = ['numberValue', 'no'];
export var markerStyleProperty = ['markerStyle', 'mst'];
export var markerColorProperty = ['markerColor', 'mc'];
export var markerSizeProperty = ['markerSize', 'msz'];
export var forwardProperty = ['forward', 'fw'];
export var backwardProperty = ['backward', 'bw'];
export var interceptProperty = ['intercept', 'itr'];
export var isDisplayRSquaredProperty = ['isDisplayRSquared', 'idrs'];
export var isDisplayEquationProperty = ['isDisplayEquation', 'ideq'];
export var seriesNameProperty = ['seriesName', 'sn'];
export var dataLabelProperty = ['dataLabel', 'sl'];
export var errorBarProperty = ['errorBar', 'eb'];
export var seriesFormatProperty = ['seriesFormat', 'sf'];
export var trendLinesProperty = ['trendLines', 'tl'];
export var dataPointsProperty = ['dataPoints', 'dp'];
export var firstSliceAngleProperty = ['firstSliceAngle', 'fsa'];
export var holeSizeProperty = ['holeSize', 'hsz'];
export var isLegendKeyProperty = ['isLegendKey', 'lk'];
export var isBubbleSizeProperty = ['isBubbleSize', 'bsz'];
export var isCategoryNameProperty = ['isCategoryName', 'icn'];
export var isSeriesNameProperty = ['isSeriesName', 'isn'];
export var isValueProperty = ['isValue', 'iv'];
export var isPercentageProperty = ['isPercentage', 'ip'];
export var isLeaderLinesProperty = ['isLeaderLines', 'il'];
export var showSeriesKeysProperty = ['showSeriesKeys', 'sk'];
export var hasHorizontalBorderProperty = ['hasHorzBorder', 'hhbdr'];
export var hasVerticalBorderProperty = ['hasVertBorder', 'hvbdr'];
export var hasBordersProperty = ['hasBorders', 'hbdr'];
export var categoryTypeProperty = ['categoryType', 'ct'];
export var chartCategoryProperty = ['chartCategory', 'c'];
export var chartSeriesProperty = ['chartSeries', 'cs'];
export var chartAreaProperty = ['chartArea', 'ca'];
export var chartTitleAreaProperty = ['chartTitleArea', 'cta'];
export var plotAreaProperty = ['plotArea', 'pa'];
export var chartLegendProperty = ['chartLegend', 'cl'];
export var chartPrimaryCategoryAxisProperty = ['chartPrimaryCategoryAxis', 'cpca'];
export var chartPrimaryValueAxisProperty = ['chartPrimaryValueAxis', 'cpva'];
export var chartTitleProperty = ['chartTitle', 'ctt'];
export var chartTypeProperty = ['chartType', 'ct'];
export var gapWidthProperty = ['gapWidth', 'gw'];
export var overlapProperty = ['overlap', 'o'];
export var chartDataTableProperty = ['chartDataTable', 'cdt'];
export var textProperty = ['text', 'tlp'];
export var shapeIdProperty = ['shapeId', 'sid'];
export var alternativeTextProperty = ['alternativeText', 'at'];
export var visibleProperty = ['visible', 'v'];
export var belowTextProperty = ['belowText', 'b'];
export var widthProperty = ['width', 'w'];
export var heightProperty = ['height', 'h'];
export var widthScaleProperty = ['widthScale', 'ws'];
export var heightScaleProperty = ['heightScale', 'hs'];
export var lineFormatProperty = ['lineFormat', 'lf'];
export var fillFormatProperty = ['fillFormat', 'ff'];
export var textWrappingStyleProperty = ['textWrappingStyle', 'tws'];
export var textWrappingTypeProperty = ['textWrappingType', 'twt'];
export var verticalRelativePercentProperty = ['verticalRelativePercent', 'vrp'];
export var horizontalRelativePercentProperty = ['horizontalRelativePercent', 'hrp'];
export var zOrderPositionProperty = ['zOrderPosition', 'z'];
export var layoutInCellProperty = ['layoutInCell', 'lc'];
export var lockAnchorProperty = ['lockAnchor', 'la'];
export var autoShapeTypeProperty = ['autoShapeType', 'ast'];
export var textFrameProperty = ['textFrame', 'tf'];
export var colorProperty = ['color', 'c'];
export var fillProperty = ['fill', 'f'];
export var textVerticalAlignmentProperty = ['textVerticalAlignment', 'tva'];
export var imageStringProperty = ['imageString', 'img'];
export var metaFileImageStringProperty = ['metaFileImageString', 'mtimg'];
export var lengthProperty = ['length', 'l'];
export var isInlineImageProperty = ['isInlineImage', 'inimg'];
export var isMetaFileProperty = ['isMetaFile', 'imf'];
export var topProperty = ['top', 'tp'];
export var bottomProperty = ['bottom', 'bt'];
export var rightProperty = ['right', 'rg'];
export var leftProperty = ['left', 'lt'];
export var getImageHeightProperty = ['getimageheight', 'gih'];
export var getImageWidthProperty = ['getimagewidth', 'giw'];
export var hasFieldEndProperty = ['hasFieldEnd', 'hfe'];
export var formFieldDataProperty = ['formFieldData', 'ffd'];
export var fieldTypeProperty = ['fieldType', 'ft'];
export var enabledProperty = ['enabled', 'e'];
export var helpTextProperty = ['helpText', 'h'];
export var statusTextProperty = ['statusText', 'st'];
export var textInputProperty = ['textInput', 'ti'];
export var checkBoxProperty = ['checkBox', 'c'];
export var dropDownListProperty = ['dropDownList', 'ddl'];
export var maxLengthProperty = ['maxLength', 'ml'];
export var defaultValueProperty = ['defaultValue', 'dv'];
export var formatProperty = ['format', 'f'];
export var sizeTypeProperty = ['sizeType', 'szt'];
export var sizeProperty = ['size', 'sz'];
export var checkedProperty = ['checked', 'chk'];
export var dropDownItemsProperty = ['dropDownItems', 'ddi'];
export var selectedIndexProperty = ['selectedIndex', 'si'];
export var commentIdProperty = ['commentId', 'cid'];
export var commentCharacterTypeProperty = ['commentCharacterType', 'cct'];
export var authorProperty = ['author', 'a'];
export var initialProperty = ['initial', 'i'];
export var dateProperty = ['date', 'dt'];
export var doneProperty = ['done', 'dn'];
export var replyCommentsProperty = ['replyComments', 'rc'];
export var revisionTypeProperty = ['revisionType', 'rt'];
export var revisionIdProperty = ['revisionId', 'rid'];
export var itemIDProperty = ['itemID', 'iid'];
export var xmlProperty = ['xml', 'x'];
export var footnoteTypeProperty = ['footnoteType', 'fnt'];
export var symbolCodeProperty = ['symbolCode', 'sc'];
export var symbolFontNameProperty = ['symbolFontName', 'sfn'];
export var customMarkerProperty = ['customMarker', 'cm'];
export var inlinesProperty = ['inlines', 'i'];
export var contentControlPropertiesProperty = ['contentControlProperties', 'ccp'];
export var lockContentControlProperty = ['lockContentControl', 'lcc'];
export var lockContentsProperty = ['lockContents', 'lc'];
export var tagProperty = ['tag', 'tg'];
export var titleProperty = ['title', 'tt'];
export var hasPlaceHolderTextProperty = ['hasPlaceHolderText', 'hpt'];
export var multiLineProperty = ['multiline', 'mul'];
export var isTemporaryProperty = ['isTemporary', 'it'];
export var dateCalendarTypeProperty = ['dateCalendarType', 'dtct'];
export var dateStorageFormatProperty = ['dateStorageFormat', 'dtsf'];
export var dateDisplayLocaleProperty = ['dateDisplayLocale', 'dtdl'];
export var dateDisplayFormatProperty = ['dateDisplayFormat', 'dtdf'];
export var isCheckedProperty = ['isChecked', 'ichk'];
export var uncheckedStateProperty = ['uncheckedState', 'uchk'];
export var checkedStateProperty = ['checkedState', 'chks'];
export var contentControlListItemsProperty = ['contentControlListItems', 'ccli'];
export var xmlMappingProperty = ['xmlMapping', 'x'];
export var fontProperty = ['font', 'f'];
export var valueProperty = ['value', 'v'];
export var displayTextProperty = ['displayText', 'dt'];
export var isMappedProperty = ['isMapped', 'imap'];
export var isWordMlProperty = ['isWordMl', 'iwml'];
export var prefixMappingProperty = ['prefixMapping', 'pmap'];
export var xPathProperty = ['xPath', 'xp'];
export var storeItemIdProperty = ['storeItemId', 'siid'];
export var customXmlPartProperty = ['customXmlPart', 'cxp'];
export var idProperty = ['id', 'i'];
export var cellFormatProperty = ['cellFormat', 'tcpr'];
export var rowFormatProperty = ['rowFormat', 'trpr'];
export var cellsProperty = ['cells', 'c'];
export var rowsProperty = ['rows', 'r'];
export var descriptionProperty = ['description', 'desc'];
export var wrapTextAroundProperty = ['wrapTextAround', 'wta'];
export var positioningProperty = ['positioning', 'p'];
export var tableFormatProperty = ['tableFormat', 'tblpr'];
export var allowOverlapProperty = ['allowOverlap', 'ao'];
export var distanceTopProperty = ['distanceTop', 'dt'];
export var distanceRightProperty = ['distanceRight', 'dr'];
export var distanceLeftProperty = ['distanceLeft', 'dl'];
export var distanceBottomProperty = ['distanceBottom', 'db'];
export var verticalOriginProperty = ['verticalOrigin', 'vo'];
export var verticalPositionProperty = ['verticalPosition', 'vp'];
export var horizontalOriginProperty = ['horizontalOrigin', 'ho'];
export var horizontalAlignmentProperty = ['horizontalAlignment', 'ha'];
export var horizontalPositionProperty = ['horizontalPosition', 'hp'];
export var blocksProperty = ['blocks', 'b'];
export var headerProperty = ['header', 'h'];
export var footerProperty = ['footer', 'f'];
export var evenHeaderProperty = ['evenHeader', 'eh'];
export var evenFooterProperty = ['evenFooter', 'ef'];
export var firstPageHeaderProperty = ['firstPageHeader', 'fph'];
export var firstPageFooterProperty = ['firstPageFooter', 'fpf'];
export var headersFootersProperty = ['headersFooters', 'hf'];
export var sectionFormatProperty = ['sectionFormat', 'secpr'];
export var listLevelPatternProperty = ['listLevelPattern', 'lilp'];
export var followCharacterProperty = ['followCharacter', 'fchar'];
export var startAtProperty = ['startAt', 'sa'];
export var restartLevelProperty = ['restartLevel', 'rl'];
export var levelNumberProperty = ['levelNumber', 'lvlno'];
export var numberFormatProperty = ['numberFormat', 'nf'];
export var abstractListIdProperty = ['abstractListId', 'alid'];
export var levelsProperty = ['levels', 'lvl'];
export var overrideListLevelProperty = ['overrideListLevel', 'o'];
export var levelOverridesProperty = ['levelOverrides', 'lo'];
export var separatorProperty = ['separator', 's'];
export var continuationSeparatorProperty = ['continuationSeparator', 'cs'];
export var continuationNoticeProperty = ['continuationNotice', 'cn'];
export var bookmarkTypeProperty = ['bookmarkType', 'bkt'];
export var propertiesProperty = ['properties', 'p'];
export var tabJustificationProperty = ['tabJustification', 'tj'];
export var positionProperty = ['position', 'p'];
export var deletePositionProperty = ['deletePosition', 'dp'];
export var leaderProperty = ['leader', 'l'];
export var tabLeaderProperty = ['tabLeader', 'tl'];
export var editRangeIdProperty = ['editRangeId', 'erid'];
export var columnFirstProperty = ['columnFirst', 'cft'];
export var columnLastProperty = ['columnLast', 'clt'];
export var userProperty = ['user', 'u'];
export var groupProperty = ['group', 'g'];
export var editableRangeStartProperty = ['editableRangeStart', 'ers'];
export var spaceProperty = ['space', 'sp'];
export var fontSchemeProperty = ['fontScheme', 'fsc'];
export var fontSchemeNameProperty = ['fontSchemeName', 'fscn'];
export var majorFontSchemeProperty = ['majorFontScheme', 'majfs'];
export var minorFontSchemeProperty = ['minorFontScheme', 'minfs'];
export var fontSchemeListProperty = ['fontSchemeList', 'fsl'];
export var fontTypefaceProperty = ['fontTypeface', 'ft'];
export var typefaceProperty = ['typeface', 't'];
export var panoseProperty = ['panose', 'p'];
export var typeProperty = ['type', 't'];
export var majorUnitProperty = ['majorUnit', 'maju'];
export var maximumValueProperty = ['maximumValue', 'maxv'];
export var minimumValueProperty = ['minimumValue', 'minv'];
export var hasMajorGridLinesProperty = ['hasMajorGridLines', 'hmajgl'];
export var hasMinorGridLinesProperty = ['hasMinorGridLines', 'hmingl'];
export var majorTickMarkProperty = ['majorTickMark', 'majtm'];
export var minorTickMarkProperty = ['minorTickMark', 'mintm'];
export var tickLabelPositionProperty = ['tickLabelPosition', 'tlp'];
export var rgbProperty = ['rgb', 'rgb'];
export var appearanceProperty = ['appearance', 'a'];
export var lineFormatTypeProperty = ['lineFormatType', 'lft'];
export var allowSpaceOfSameStyleInTableProperty = ['allowSpaceOfSameStyleInTable', 'stytbl'];
export var weightProperty = ['weight', 'w'];
export var inlineFormatProperty = ['inlineFormat', 'inf'];
export var fontNameProperty = ['fontName', 'fn'];
export var isCompressedProperty = ['isCompressed', 'comp'];
export var columnIndexProperty = ['columnIndex', 'ci'];
export var imagesProperty = ['images', 'imgs'];
export var isAfterParagraphMarkProperty = ['isAfterParagraphMark', 'apm'];
export var isAfterCellMarkProperty = ['isAfterCellMark', 'acm'];
export var isAfterRowMarkProperty = ['isAfterRowMark', 'arm'];
export var gridProperty = ['grid', 'grd'];
export var columnCountProperty = ['columnCount', 'colc'];
export var isAfterTableMarkProperty = ['isAfterTableMark', 'atm'];
